import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Timeline" />
    <h1>Roooooster</h1>
    <h2>Vrijdag</h2>
    <p>18:00 Start Low Zuthem</p>
    <p>21:00 Ranking the stars</p>
    <h2>Zaterdag</h2>
    <p>8:30 Ontbijt</p>
    <p>9:30 Vertrek activiteit (uiterlijk)</p>
    <p>10:00 Activiteit</p>
    <p>12:00 Einde activiteit</p>
    <p>12:30 Lunch</p>
    <p>14:30 HollandseSpelen</p>
    <p>16:30 Cocktail workshop</p>
    <p>18:00 Start BBQ+fissa</p>
  </Layout>
)

export default SecondPage
